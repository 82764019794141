html.dark {
  --bg: #1e293b;
  --color-1: #111827;
  --color-2: #94a3b8;
  --color-3: #94a3b8
}

html {
  --primary: #DE7C24;
  --bg: #fff;
  --color-1: #fff;
  --color-2: #959595;
  --color-3: #000;
};


.select__control {
    width: 100%;
    border: 1px solid var(--color-1) !important;
    color: var(--color-2) !important;
    border-radius: 4px !important;
    overflow: auto !important;
  }
  
  .css-1s2u09g-control{
    background-color: var(--bg) !important;
    border: 1px solid var(--color-2) !important;
    color: var(--color-3) !important;
  }
  
  .css-qc6sy-singleValue{
  color: var(--color-3) !important;
}

.select__control::placeholder, .input-form::placeholder {
  color: var(--color-2) !important;
}

.input-form {
    padding: 6px 10px !important;
    width: 100%;
    border: 1px solid var(--color-2);
    border-radius: 4px !important;
    overflow: auto !important;
    background-color: var(--bg);
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--primary);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--primary);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }